.header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem 5rem;
  align-items: center;
  margin-bottom: 5rem;
  .logo {
    font-family: "Pacifico", cursive;
    font-size: 3rem;
    color: white;
  }
  nav {
    justify-self: end;
    ul {
      display: flex;
      li {
        padding-left: 2.5rem;
        a {
          text-decoration: none;
          color: white;
        }
      }
    }
  }
}

@media screen and (max-width: 1099px) {
  .header {
    padding: 2rem;
    margin-bottom: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .header {
    margin-bottom: 3rem;
    .logo {
      font-size: 2.3rem;
    }
    nav {
      ul {
        li {
          padding-left: 1.7rem;
          font-size: 1.4rem;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .header {
    .logo {
      font-size: 1.9rem;
    }
    nav {
      ul {
        li {
          padding-left: 1.5rem;
          font-size: 1.4rem;
        }
      }
    }
  }
}
