.footer {
  padding: 10rem 0 20rem 0;
  h2 {
    &::before {
      content: "04";
      font-size: 7rem;
      position: absolute;
      left: -10rem;
      line-height: 10rem;
      font-weight: 400;
      font-style: italic;
    }
  }
  &__content {
    ul {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(3, 1fr);
    }
    h3 {
      margin-bottom: 2.5rem;
      font-size: 1.8rem;
    }
    a {
      color: #c7ac75;
      font-size: 2rem;
    }
    .fab,
    .fas {
      margin-right: 0.75rem;
    }
  }
}

@media screen and (max-width: 1099px) {
  .footer {
    padding: 10rem 0;
    h2 {
      &::before {
        display: none;
      }
    }
    &__content {
      h3 {
        font-size: 1.4rem;
      }
      a {
        font-size: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 76.8rem) {
  .footer {
    padding: 5rem 0;
    &__content {
      ul {
        justify-content: start;
        grid-template-columns: 1fr;
        li {
          margin-bottom: 5rem;
        }
      }
      h3 {
        margin-bottom: 2rem;
      }
      a {
        font-size: 1.6rem;
      }
    }
  }
}
