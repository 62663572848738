.skills {
  h2 {
    &::before {
      content: "03";
      font-size: 7rem;
      position: absolute;
      left: -10rem;
      line-height: 10rem;
      font-weight: 400;
      font-style: italic;
    }
  }
  &__content {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(2, 1fr);
    h3 {
      font-size: 3.4rem;
      list-style: 4rem;
      margin-bottom: 5rem;
    }
    li {
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      img {
        width: 2rem;
        height: auto;
        margin-right: 1rem;
      }
    }
  }
}

@media screen and (max-width: 1099px) {
  .skills {
    h2 {
      &::before {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .skills {
    &__content {
      display: grid;
      justify-content: start;
      grid-template-columns: 1fr;
      h3 {
        font-size: 2.5rem;
        list-style: 3.5rem;
        margin-bottom: 3rem;
      }
      li {
        padding-bottom: 1rem;
      }
      &__lang {
        margin-top: 6rem;
      }
    }
  }
}
