.work {
  h2 {
    &::before {
      content: "01";
      font-size: 7rem;
      position: absolute;
      left: -10rem;
      line-height: 10rem;
      font-weight: 400;
      font-style: italic;
    }
  }
}

@media screen and (max-width: 1099px) {
  .work {
    h2 {
      &::before {
        display: none;
      }
    }
  }
}
