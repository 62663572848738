@import "~reset-css/reset.css";

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  background-color: transparent;
  &:hover {
  }
}

h2 {
  font-size: 12rem;
  line-height: 14.4rem;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 10rem;
}
.h3,
h3 {
}
.h4,
h4 {
}
.h5,
h5 {
}
.h6,
h6 {
}
p {
  margin-bottom: 2.5rem;
  line-height: 2.6rem;
}

body {
  font-size: 1.6rem;
  font-family: "Roboto", sans-serif;
  color: white;
  background-color: #212121;
}

.container {
  max-width: 110rem;
  margin: 0 auto;
}

section {
  padding: 10rem 0;
}

@media screen and (max-width: 1099px) {
  h2 {
    font-size: 9rem;
    line-height: 11rem;
    margin-bottom: 8rem;
  }
  .container {
    padding: 0 2rem;
  }
  section {
    padding: 8rem 0 5rem 0;
  }
}

@media screen and (max-width: 768px) {
  h2 {
    font-size: 5.2rem;
    line-height: 8rem;
    margin-bottom: 5rem;
  }
  section {
    padding: 6rem 0 2rem 0;
  }
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 4.2rem;
    line-height: 6rem;
    margin-bottom: 3rem;
  }
}
