.about {
  h2 {
    &::before {
      content: "02";
      font-size: 7rem;
      position: absolute;
      left: -10rem;
      line-height: 10rem;
      font-weight: 400;
      font-style: italic;
    }
  }
  .p--first {
    font-size: 3rem;
    line-height: 4.2rem;
    max-width: 95rem;
  }
  &__content {
    padding-top: 5rem;
    display: grid;
    grid-template-columns: 1.75fr 1fr;
    justify-content: space-between;
    img {
      width: 85%;
      height: auto;
    }
  }
}

@media screen and (max-width: 1099px) {
  .about {
    h2 {
      &::before {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .about {
    .p--first {
      font-size: 2.2rem;
      line-height: 3.4rem;
    }
    &__content {
      padding-top: 2rem;

      grid-template-columns: 1fr;
      justify-content: start;
      img {
        width: 100%;
        margin-bottom: 3rem;
      }
    }
  }
}
