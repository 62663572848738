.intro-title {
  color: #c7ac75;
  font-size: 7.2rem;
  font-weight: bold;
  line-height: 9rem;
}

@media screen and (max-width: 1099px) {
  .intro-title {
    font-size: 6rem;
    line-height: 8rem;
  }
}

@media screen and (max-width: 768px) {
  .intro-title {
    font-size: 4rem;
    line-height: 5.5rem;
  }
}

@media screen and (max-width: 600px) {
  .intro-title {
    font-size: 3rem;
    line-height: 4.2rem;
  }
}
