.work-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;

  li {
    a {
      display: flex;
      justify-items: center;
      flex-direction: column;
      color: white;
    }
    img {
      width: 100%;
    }
    h3,
    p {
      text-align: center;
    }
  }
}

@media screen and (max-width: 768px) {
  .work-list {
    grid-template-columns: 1fr;
  }
}
