.work-single-page {
  &__paragraph {
    max-width: 70rem;
  }
  img {
    width: 100%;
    padding: 13rem 0 10rem 0;
  }
  a {
    color: #c7ac75;
  }
}

@media screen and (max-width: 768px) {
  .work-single-page {
    img {
      padding: 6rem 0 2rem 0;
    }
  }
}
